@font-face {
    font-family: 'proxima';
    src: url('../public/fonts/proxima_regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'proxima';
    src: url('../public/fonts/proxima_semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'proxima';
    src: url('../public/fonts/proxima_bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'proxima';
    src: url('../public/fonts/proxima_extrabold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'proxima';
    src: url('../public/fonts/proxima_black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}


@import "react-slideshow-image/dist/styles.css";


@tailwind base;
@tailwind components;
@tailwind utilities;



/* custom scrollbar */
@layer utilities {




    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;


        /* Firefox */
    }



}




/* imported fonts */